<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="800px" @close="close">
    <div class="flexBox">
      <el-tag style="margin-right: 15px" type="danger">
        *导入用户标准模版
      </el-tag>
      <el-button type="primary" @click="downLoadTemplate()">模版下载</el-button>
    </div>

    <el-form style="margin-top: 20px">
      <el-form-item label="文件上传">
        <el-upload ref="upLoadBox" accept=".xlsx" action="#" :auto-upload="false" class="upload-demo" :limit="1" drag :file-list="fileList" :on-change="changeFile" :on-exceed="handleExceed">
          <!--
      :on-remove="handleRemove" -->
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            拖拽文件到这里或者
            <em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">Excel 文件 大小不超过100M</div>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :loading="upLoading" type="primary" @click="save">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  onMounted,
} from 'vue'
import { enterpriseImportUser } from '@/api/userManagement'
// 引入下拉数选择
export default defineComponent({
  name: 'UserManagementEdit',
  components: {},
  props: {
    eventId: {
      type: String,
      default: '',
    },
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    // 自定义手机验证
    const subLabel = ref('选择展会')
    const state = reactive({
      formRef: null,
      upLoadBox: null,
      form: {},
      identityType: '',
      file: null,
      fileList: [],
      departmentData: [],
      rules: {
        channelName: [
          { required: true, trigger: 'blur', message: '请输入渠道名称' },
        ],
        remarks: [
          { required: true, trigger: 'blur', message: '请输入渠道备注' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      // HOLD_MEETING EXHIBITION FORUM
      exhList: [
        {
          label: '会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '展会',
          value: 'EXHIBITION',
        },
        {
          label: '论坛',
          value: 'FORUM',
        },
      ],
      subExhList: [],
      identityList: [],
      meetingCode: '', // 二级菜单传递code
      meetingType: '', // 一级菜单传递type
      upLoading: false,
    })
    const showEdit = async (row) => {
      state.title = '用户导入'
      state.form = Object.assign({}, row)
      state.meetingCode = proxy.$route.query.meetingCode
      state.meetingType = proxy.$route.query.type || 'EXHIBITION'
      state.subExhList = []
      state.file = null
      state.identityType = ''
      fetchMeetData(proxy.$route.query.meetingCode)
      state.dialogFormVisible = true
      // state.upLoadBox.clearFiles()
      state.fileList = []
    }
    const upLoadFile = (e) => {
      console.log(e)
    }

    const fetchMeetData = (eventId) => {
      // getIdentityList(eventId).then((res) => {
      //   console.log(res)
      //   // state.identityList = res.data
      // })
    }
    const downLoadTemplate = (e) => {
      // console.log(e)
      // downTemplate().then((res) => {
      //   console.log(res)
      // })

      window.open(
        `https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/%E7%94%A8%E6%88%B7%E6%B1%A0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx`
      )
    }
    // 下拉事件
    const handleChangeSelect = (e) => {
      console.log('e', e)
      state.form.meetingType = e
      let item = state.exhList.find((item) => item.value == state.meetingType)
      console.log('item', item)
      subLabel.value = item.label
      state.meetingCode = ''
      // 拿到二级菜单的数据
      _importGetExhInfo(state.meetingType).then((res) => {
        console.log(res)
        let mapArr = [...res.data]
        state.subExhList = mapArr.map((item) => {
          return {
            label: item.meetingName,
            value: item.meetingCode,
          }
        })
      })
    }
    const handleSubChangeSelect = (e) => {
      console.log('ehandleSubChangeSelect', e)
      state.form.meetingCode = e
    }
    const changeFile = (val) => {
      console.log(val)
      // console.log("选择文件成功");
      let nameArray = val.name.split('.')
      if (nameArray[nameArray.length - 1] === 'xlsx') {
        // file保存选择的文件
        state.file = val.raw
      } else {
        this.$message({
          message: '表格格式错误,请确认表格内容并重新上传',
          type: 'warning',
          duration: 2000,
        })
      }
    }
    const close = () => {
      state.form = {}
      state.dialogFormVisible = false
    }
    const save = () => {
      if (state.upLoading) {
        return false
      }
      state.upLoading = true
      let formData = new FormData()
      formData.append('file', state.file)
      enterpriseImportUser(formData)
        .then((res) => {
          console.log(res, state.upLoadBox)
          proxy.$baseMessage(
            '导入完成，导入结果在导入结果按钮点击查看',
            'success',
            'vab-hey-message-success'
          )
          state.meetingType = ''
          state.identityType = ''
          state.dialogFormVisible = false
          state.upLoading = false
          emit('fetch-data')
        })
        .catch(() => {
          proxy.$baseMessage(
            '导入失败，请在下载中的Excel文件中查看原因',
            'error',
            'vab-hey-message-error'
          )
          state.dialogFormVisible = false
          state.upLoading = false
          emit('fetch-data')
        })
    }
    const getIdentityData = async () => {
      let resp = await getIdentity()
      console.log(`output->resp`, resp)
      state.identityList = [...resp.data].map((item) => {
        return {
          label: item.name,
          value: item.identityType,
        }
      })
      console.log(`output->"state.identityList`, state.identityList)
    }
    onMounted(() => {
      // getIdentityData()
    })

    const handleExceed = (files) => {
      proxy.$baseMessage(
        `当前限制选择1 个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    return {
      ...toRefs(state),
      subLabel,
      showEdit,
      changeFile,
      fetchMeetData,
      downLoadTemplate,
      close,
      upLoadFile,
      save,
      handleChangeSelect,
      handleSubChangeSelect,
      getIdentityData,
      handleExceed,
    }
  },
})
</script>

<style lang="scss" scoped>
.flexBox {
  display: flex;
  align-content: center;
  justify-content: right;
}
</style>
